import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import imgAskEmmCover from "../assets/images/ask-emm-bg.png"

const AskEmmPage = () => (
  <Layout lang="ar">
    <SEO title="Ask Us" lang="ar" />
    <div id="modal-example" className="uk-flex-top" data-uk-modal>
    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical" style={{background: "none", width: "auto"}}>
    {/* <button className="uk-modal-close-outside" type="button" uk-close>CLOSE</button> */}
        <h2 className="relative">
        شكراً على إرسال سؤالك إلينا
                    <div className="text-underline-heavy"></div>
                  </h2>
        <p className="text-white">علماء مشروع الإمارات لاستكشاف المريخ سيمدونك بالإجابة التي تبحث عنها خلال أسبوعين إلى 4 أسابيع</p>
    </div>
    </div>
    <main>
      <div className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 40">
        <img src={imgAskEmmCover} alt="" style={{transform: "translate(50%,-50%)"}} data-uk-cover />
        <div className="uk-overlay uk-position-cover header-overlay"></div>
      </div>
      <section id="about-emm" style={{marginTop: "-300px", position: "relative"}}>
            <div className="standard-container-p0 pv4 ph5">
                  <h2 className="relative">
                  اسأل فريق مشروع الإمارات لاستكشاف المريخ
                    <div className="text-underline-heavy"></div>
                  </h2></div>
            <div className="large-container pb6">
            <div className="standard-container standard-bg">
              <h3 className="uk-text-uppercase text-highlight-brow">
              هل لديك سؤالاً حول الكوكب الأحمر أو مشروع الإمارات لاستكشاف المريخ؟
              </h3>
              <p>
              أرسل لنا أسئلتك من خلال النموذج أدناه وسنقوم بالرد عليك في أقرب وقت.              
              </p>
                <div className="mt5">
                <form className="uk-form-horizontal uk-margin-large dark-form">

                  <div className="uk-margin">
                      <label className="uk-form-label" style={{float: "right", textAlign: "left"}} for="name">الاسم</label>
                      <div className="uk-form-controls">
                          <input className="uk-input" id="name" type="text" />
                      </div>
                  </div>
                  <div className="uk-margin">
                      <label className="uk-form-label" style={{float: "right", textAlign: "left"}} for="email">البريد الإلكتروني</label>
                      <div className="uk-form-controls">
                          <input className="uk-input" id="form-horizontal-text" type="email" />
                      </div>
                  </div>
                  <div className="uk-margin">
                      <label className="uk-form-label" style={{float: "right", textAlign: "left"}} for="question-about">سؤالك عن</label>
                      <div className="uk-form-controls">
                          <select className="uk-select" id="question-about">
                              <option>
                              سؤالك عن                               
                              </option>
                              <option>
                              المريخ                              
                              </option>
                              <option>
                              الاستكشاف 
                              </option>
                              <option>
                              المهمة
                              </option>
                              <option>
                              مسبار الأمل                              
                              </option>
                          </select>
                      </div>
                  </div>
                  <div className="uk-margin">
                      <label className="uk-form-label" style={{float: "right", textAlign: "left"}} for="form-horizontal-text">السؤال</label>
                      <div className="uk-form-controls">
                        <textarea className="uk-textarea" rows="5"></textarea>
                      </div>
                  </div>
                  <div className="uk-margin uk-text-left">
                    <button className="uk-button uk-button-default" type="button" data-uk-toggle="target: #modal-example">إرسال</button>
                  </div>
                </form>
                </div>
            </div>         
          </div>  
      </section>
    </main>
  </Layout>
)

export default AskEmmPage
